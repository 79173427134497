import { cloneDeep, find } from 'lodash-es';

import { CREATE_TAG_SUCCESS, FETCH_TAGS_SUCCESS, PATCH_TAGS_SUCCESS } from '../actions';

import type { Tag } from '../types/Tag';

const initialState = {
  tags: []
};

const tagsReducer = (state = initialState.tags, action: any) => {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS: {
      return cloneDeep(action.tags);
    }
    case PATCH_TAGS_SUCCESS: {
      const oldState: any = cloneDeep(state);
      const updatedTag: any = find(oldState, (tag: Tag) => {
        return tag.id === 'TAG' + action.tag.id;
      });
      const newTag = { ...action.tag, id: 'TAG' + action.tag.id };
      const indexToBeUpdated = oldState.findIndex((tag: Tag) => tag.id === updatedTag?.id);
      oldState.splice(indexToBeUpdated, 1, newTag);
      return oldState;
    }
    case CREATE_TAG_SUCCESS: {
      const oldState: any = cloneDeep(state);
      return [...oldState, ...[action.tag]];
    }
    default:
      return state;
  }
};

export default tagsReducer;
