import { isEqual } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { ResponseTemplate } from '@eeedo/types';

import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { KnowledgeBank } from './KnowledgeBank';
import { ReplyMethod } from './ReplyMethod';
import ReplyTemplates from './ReplyTemplates';
import FeatureFlags from 'src/api/FeatureFlags';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import { Channels } from 'src/types/Channel';
import { serializeContent } from 'src/Utilities/parseUtils';

import type { ReplyMethodProps } from './ReplyMethod';
import type { ReplyChannelTabBarProps } from 'src/containers/DraftsContainer';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData } from 'src/types/User';

const ReplyEditor = React.lazy(() => import('src/Components/Case/ReplyEditor'));

interface ReplyIntraProps extends ReplyMethodProps<ReplyIntraState> {
  userData: PersonalData;
  entities: Entity[];
  ticketType: TicketType;
  templates: Array<ResponseTemplate>;
  drafts?: Partial<ReplyIntraState>;
  task: Ticket;

  onSubmit: ReplyChannelTabBarProps['onSubmit'];
}

interface ReplyIntraState {
  content: string;
  isLoading?: boolean;
  selectedReplyTemplate?: string | undefined;
}

class ReplyIntra extends ReplyMethod<ReplyIntraProps, ReplyIntraState> {
  private channel = ChannelType.Internal;

  constructor(props: ReplyIntraProps) {
    super(props);

    this.state = this.getInitialState(this.props.drafts);
  }

  getDraftChannel(): Channels {
    return Channels.intra;
  }

  getDraftState(state: ReplyIntraState): Partial<ReplyIntraState> {
    return {
      content: state.content,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private getInitialState = (drafts: ReplyIntraProps['drafts']) => {
    const state: ReplyIntraState = {
      isLoading: false,
      content: drafts?.content || '',
      selectedReplyTemplate: drafts?.selectedReplyTemplate || undefined
    };
    return state;
  };

  private clearFields = () => {
    this.setState({ content: '', selectedReplyTemplate: undefined }, () => {
      this.saveDraft(this.state);
    });
  };

  public submitComment = () => {
    if (this.state.isLoading || !this.state.content.length) {
      return;
    }

    this.setState({ isLoading: true });

    const bodyOfRequest = {
      content: serializeContent(this.state.content, this.props.task.attachments),
      sendAsMail: false,
      channel: ChannelType.Internal
    };
    const request = this.props.onSubmit(bodyOfRequest, false);

    request
      .then((response: any) => {
        if (response !== false) {
          this.clearFields();
        }
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  componentWillReceiveProps(nextProps: ReplyIntraProps) {
    if (!isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts));
    }
  }

  private handleInputChange = (content: string) => {
    this.setState({ content }, () => this.saveDraft(this.state));
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            <Form.Field>
              <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
              <ReplyTemplates
                userData={this.props.userData}
                ticketType={this.props.ticketType}
                entities={this.props.entities}
                templates={this.props.templates}
                task={this.props.task}
                channel={this.channel}
                selectedOption={this.state.selectedReplyTemplate}
                setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                setContent={(value) => this.updateState({ ...value })}
                content={this.state.content}
              />
            </Form.Field>
            {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
              <Form.Field>
                <KnowledgeBank
                  title={this.state.content}
                  id={this.props.taskId}
                  extraArguments={this.props.ticketType.knowledgeBank}
                  content={this.state.content}
                  updateState={(value: string) => {
                    this.updateState({ content: value });
                  }}
                />
              </Form.Field>
            )}
            <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
            <Form.Field id="commentContentField">
              <label>{t('ADD_COMMENT_CONTENT')}</label>
              <ReplyEditor
                style={{
                  borderRadius: '5px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  wordBreak: 'break-word'
                }}
                value={this.state.content}
                onChange={this.handleInputChange}
                editorLanguage={this.props.userData.language}
                ticket={this.props.task}
                onKeyDown={this.handleHotKeys}
              />
            </Form.Field>
            <ReplyControlButtons
              internal
              small={this.props.smallButtons}
              disabled={this.state.isLoading || !this.state.content.length}
              loading={this.state.isLoading}
              onClear={this.clearFields}
              onSaveDraft={() => this.saveVCDraft(false)}
              onSubmit={this.submitComment}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default ReplyIntra;
