import React, { useRef } from 'react';
import { Popup } from 'semantic-ui-react';

import TicketAdditionalDetails from './TicketAdditionalDetails';
import ErrorBoundary from 'src/ErrorBoundary';

import type { TicketDetailsData } from '../../types/Ticket';
import type { PopupPosition } from 'src/types/PopupTypes';
import type { FieldSet } from 'src/types/TicketType';

interface TicketPreviewPopupProps {
  fields: FieldSet[] | undefined;
  id: string;
  title: string;
  popupTitle: string;
  type: string | undefined;
  created: number;
  touched: number;
  dueDate: number;
  position: PopupPosition;
  data?: TicketDetailsData;
  onLoaded: () => void;
  ellipsis?: boolean;
}

const TicketPreviewPopup = (props: TicketPreviewPopupProps) => {
  const popupContext = useRef<HTMLSpanElement>(null);
  const rect = popupContext.current?.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();
  const position = bodyRect?.bottom - Number(rect?.bottom) > 100 ? props.position : 'top right';

  return (
    <Popup
      position={position}
      context={popupContext.current || undefined}
      wide={false}
      trigger={
        <span
          ref={popupContext}
          style={{
            display: 'inline-block',
            verticalAlign: 'bottom',
            whiteSpace: 'nowrap',
            ...(props.ellipsis && {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '80%'
            })
          }}
        >
          {props.title}
        </span>
      }
      content={
        <ErrorBoundary>
          <TicketAdditionalDetails
            fields={props.fields}
            title={props.popupTitle}
            id={props.id}
            ticketType={props.type}
            created={props.created}
            touched={props.touched}
            dueDate={props.dueDate}
            originalMsg={true}
            data={props.data}
            onLoaded={props.onLoaded}
          />
        </ErrorBoundary>
      }
      on={'hover'}
      hoverable={true}
    />
  );
};

export default TicketPreviewPopup;
