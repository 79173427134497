import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Icon, Label, Menu, Popup } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';

import { clearCommentFilter } from 'src/actions/commentFiltersActions';
import FeatureFlags from 'src/api/FeatureFlags';
import EventsToggle from 'src/Components/Comments/EventsToggle';
import ImagesToggle from 'src/Components/Comments/ImagesToggle';
import { CommentsMenuItem } from 'src/types/Comments';
import { Direction } from 'src/types/Sorting';

import type { CommentsTab } from 'src/types/Comments';
import type { State } from 'src/types/initialState';

interface CommentsMenuProps extends ConnectedProps<typeof connector> {
  caseId: string;
  direction: Direction;
  mobileMode: boolean;
  showedCommentsLength: number;

  onDirectionClick(): void;
  setCommentFiltersModalOpen: (state: boolean) => void;
}

export const CommentsMenu = ({
  caseId,
  mobileMode,
  direction,
  showedCommentsLength,
  commentFilters,
  clearCommentFilter,
  onDirectionClick,
  setCommentFiltersModalOpen
}: CommentsMenuProps) => {
  const { t } = useTranslation();

  const menuTab = {
    id: 'discussionTab',
    title: t('CASE_DISCUSSION'),
    key: CommentsMenuItem.messages,
    name: 'comments'
  } as CommentsTab;

  const showImagesToggle = FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES_TOGGLE');

  return (
    <Menu attached="top" tabular>
      <Menu.Item id={menuTab.id} key={menuTab.key}>
        <Icon name={menuTab.name} />
        {mobileMode || menuTab.title}
        <Label color="blue">{showedCommentsLength}</Label>
      </Menu.Item>

      <div className="commentsMenu__buttonsWrapper">
        <ButtonGroup size="tiny">
          {commentFilters && !!Object.keys(commentFilters).length && (
            <Popup
              inverted
              size="tiny"
              content={t('filter.clear')}
              trigger={<Button negative icon="redo" onClick={() => clearCommentFilter(caseId)} />}
            />
          )}
          <Button onClick={() => setCommentFiltersModalOpen(true)}>{t('SEARCH_FILTERTERM')}</Button>
        </ButtonGroup>

        {showImagesToggle && <ImagesToggle />}

        <EventsToggle />

        <Button visible="true" size="tiny" icon={true} labelPosition="left" onClick={onDirectionClick}>
          <Icon name={direction === Direction.ASC ? 'arrow up' : 'arrow down'} />
          {t('comments.buttons.sort_by_date')}
        </Button>
      </div>
    </Menu>
  );
};

const connector = connect(
  (state: State) => ({
    commentFilters: state.commentFilters[state.activeTicketTab!]
  }),
  { clearCommentFilter }
);

export default connector(CommentsMenu);
