import { intersection, size, uniq } from 'lodash-es';
import moment from 'moment';
import { ascend, compose, descend, filter, prop, props, sort, sortBy, sortWith } from 'ramda';

import { userToNumericalId } from './users';
import FeatureFlags from 'src/api/FeatureFlags';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import { Direction, SortBy } from 'src/types/Sorting';

import type { Category } from 'src/types/Category';
import type { Channel } from 'src/types/Channel';
import type { TabFilter } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { Priority } from 'src/types/Priority';
import type { Tag } from 'src/types/Tag';
import type { StatusTypes, TicketListTicket } from 'src/types/Ticket';
import type { PersonalData, UserCustomTicketOrdering } from 'src/types/User';

type DueDateFixes = { [key: string]: number };

// channel weights are used for sorting
// the higher value, the more higher priority
// not defined channels are using default weight (lowest priority)
export const CHANNEL_WEIGHTS = {
  [ChannelType.Chat]: 100, // elisa chat
  [ChannelType.Giosg]: 100, // giosg
  [ChannelType.WhatsApp]: 90, // whatsapp
  [ChannelType.Facebook]: 80, // facebook
  [ChannelType.Sms]: 50 // sms
};
export const DEFAULT_CHANNEL_WEIGHT = 10;

export type SortableTicket = Pick<
  TicketListTicket,
  'id' | 'priority' | 'channel' | 'dueDate' | 'created' | 'touched' | 'taskType'
>;

export const doneTicketsOrdering = sort<SortableTicket>(descend(prop(SortBy.touched)));

const getDueMap = (userCustomOrdering?: UserCustomTicketOrdering) => {
  if (FeatureFlags.isFlagOn('ENABLE_CUSTOM_USER_TICKET_ORDERING_BY_DUEDATE') && userCustomOrdering) {
    const dueMap: DueDateFixes = {};
    userCustomOrdering.fixPerTicketType.forEach((entry) => {
      dueMap[entry.ticketTypeName] = entry.duedate_fix;
    });

    return dueMap;
  }

  return undefined;
};

const comparatorsMap = {
  [Direction.ASC]: ascend,
  [Direction.DESC]: descend
};

export const ticketOrdering = <T extends SortableTicket>(
  tickets: T[],
  orderBy: SortBy | null,
  mode: Direction,
  userCustomOrdering?: UserCustomTicketOrdering
) => {
  const firstFactor = descend<T>(prop('priority'));
  const secondFactor = descend<T>((t) => CHANNEL_WEIGHTS[t.channel] || DEFAULT_CHANNEL_WEIGHT);
  const comparators = [firstFactor, secondFactor];

  const comparator = comparatorsMap[mode] ?? ascend;
  // 3rd factor - date (now it might be dueDate, created or touched)
  if (orderBy) {
    // create duedate fix map, to add/sub modifier before sorting
    const dueMap: DueDateFixes | undefined = getDueMap(userCustomOrdering);
    const selector =
      orderBy === SortBy.dueDate && dueMap ? (t: T) => (t.dueDate || 0) + (dueMap[t.taskType] || 0) : prop(orderBy);

    comparators.push(comparator<T>(selector));
  }

  const forthFactor = comparator<T>(prop('id'));
  comparators.push(forthFactor);

  return sortWith<T>(comparators)(tickets);
};

export interface FilterTicketListArguments<T extends Partial<TicketListTicket>> {
  tickets: T[];
  status?: StatusTypes;
  personalData: PersonalData;
  filters: TabFilter;
  categories: State['categories'];
  usersList?: State['usersList']['usersList'];
}

function combineTags(stateCategories: Category[], categories: number[], tags: string[]) {
  const tagsFilter = (c: Category) => categories?.includes(c.id);
  const tagsReducer = (acc: number[], c: Category) => [...acc, ...c.tags];
  const tagsFromCategory = stateCategories.filter(tagsFilter).reduce(tagsReducer, []);

  return uniq([...(tags || []), ...(tagsFromCategory?.map?.((c) => `TAG${c}`) || [])]);
}

export const filterTicketList = <T extends Partial<TicketListTicket>>(args: FilterTicketListArguments<T>) => {
  let tickets = args.tickets;
  const { status, personalData, filters, usersList } = args;
  const currentUserId = userToNumericalId(personalData.UID);
  const {
    channel,
    taskType,
    taskTypeNOT,
    tags,
    handledBy,
    createdBy,
    title,
    handledByMe,
    handledByMeOrNoOne,
    delegatedToMe,
    notDelegated,
    delegates,
    dueDate24h,
    dueDateToday,
    dueDateOver,
    priorities,
    tagsNO,
    tagAND,
    tagNOT,
    originalContact,
    originalDirection,
    createdAfter,
    createdBefore,
    touchedAfter,
    touchedBefore,
    dueDateAfter,
    dueDateBefore,
    lastContactAddress,
    categories,
    tagCategoriesNOT
  } = filters;

  if (personalData?.permissions?.includes?.('hasLimitedView') && status === 'todo') {
    return tickets.slice(0, 1);
  }

  if (handledByMe) {
    tickets = tickets.filter((ticket) => {
      return ticket.handledByUser === currentUserId;
    });
  }

  if (Array.isArray(delegates) && delegates.length > 0) {
    tickets = tickets.filter((ticket) => {
      return (
        delegates.some((del) => ticket.delegates?.includes(del)) ||
        delegates.some((del) => ticket.delegatedTo?.includes(del))
      );
    });
  }

  if (delegatedToMe) {
    tickets = tickets.filter((ticket) => {
      return ticket.delegates?.includes(personalData.UID) || ticket.delegatedTo?.includes(personalData.UID);
    });
  }

  if (priorities) {
    tickets = tickets.filter((ticket) => {
      return priorities.some((prio) => ticket.priority === prio);
    });
  }

  if (dueDate24h) {
    tickets = tickets.filter((ticket) => {
      return ticket.dueDate! < moment().unix() + 86400;
    });
  }

  if (dueDateToday) {
    tickets = tickets.filter((ticket) => {
      return ticket.dueDate! < moment().endOf('day').unix(); // tomorrow midnight : moment().subtract(1,'days').endOf('day').unix()
    });
  }

  if (dueDateOver) {
    tickets = tickets.filter((ticket) => {
      return ticket.dueDate! <= moment().unix();
    });
  }

  if (createdAfter || createdBefore) {
    tickets = tickets.filter((ticket) => {
      const due = moment.unix(ticket.created!);
      return (
        (!createdAfter || due.isAfter(moment.unix(createdAfter))) &&
        (!createdBefore || due.isBefore(moment.unix(createdBefore)))
      );
    });
  }

  if (touchedAfter || touchedBefore) {
    tickets = tickets.filter((ticket) => {
      const due = moment.unix(ticket.touched!);
      return (
        (!touchedAfter || due.isAfter(moment.unix(touchedAfter))) &&
        (!touchedBefore || due.isBefore(moment.unix(touchedBefore)))
      );
    });
  }

  if (dueDateAfter || dueDateBefore) {
    tickets = tickets.filter((ticket) => {
      const due = moment.unix(ticket.dueDate!);
      return (
        (!dueDateAfter || due.isAfter(moment.unix(dueDateAfter))) &&
        (!dueDateBefore || due.isBefore(moment.unix(dueDateBefore)))
      );
    });
  }

  if (notDelegated) {
    tickets = tickets.filter((ticket) => {
      return size(ticket.delegatedTo) < 2 && size(ticket.delegates) < 2;
    });
  }

  if (Array.isArray(channel) && channel.length > 0) {
    tickets = tickets.filter((ticket) => {
      return channel.indexOf(ticket.channel!) !== -1;
    });
  }

  if (Array.isArray(taskType) && taskType.length > 0) {
    tickets = tickets.filter((ticket) => {
      return taskType.indexOf(ticket.taskType!) !== -1;
    });
  }

  if (Array.isArray(taskTypeNOT) && taskTypeNOT.length > 0) {
    tickets = tickets.filter((ticket) => {
      return ticket.taskType && taskTypeNOT.indexOf(ticket.taskType) === -1;
    });
  }

  if (tags?.length || categories?.length) {
    const combinedTags = combineTags(args.categories, categories!, tags!);
    tickets = [
      ...tickets.filter((ticket) => {
        return intersection(ticket.tags, combinedTags).length > 0;
      })
    ];
  }

  if (handledByMeOrNoOne === true) {
    const bots = (usersList ?? []).filter((u) => u.type === 'bot').map((u) => userToNumericalId(u.UID));
    tickets = tickets.filter(
      (ticket) =>
        !ticket.handledByUser ||
        ticket.handledByUser === currentUserId ||
        bots.includes(userToNumericalId(ticket.handledByUser))
    );
  }

  if (handledBy) {
    const uid = userToNumericalId(handledBy);
    tickets = tickets.filter((ticket) => {
      return ticket.handledByUser === uid;
    });
  }

  if (createdBy) {
    const uid = userToNumericalId(createdBy);
    tickets = tickets.filter((ticket) => {
      return ticket.UID ? ticket.UID === uid : userToNumericalId(ticket.createdByUser!) === uid;
    });
  }

  if (title) {
    const searchedTitle = title.toLowerCase();
    tickets = tickets.filter((ticket) => {
      return ticket.title?.toLowerCase().indexOf(searchedTitle) !== -1;
    });
  }

  if (tagsNO) {
    tickets = tickets.filter((ticket) => {
      return ticket.tags?.filter((tag) => tag !== 'TAG1').length === 0;
    });
  }

  if (!tagsNO && tagAND) {
    const tagsAND = typeof tagAND === 'string' ? [tagAND] : tagAND;
    tickets = tickets.filter((ticket) => {
      return tagsAND.every((tag) => ticket.tags?.includes(tag));
    });
  }

  if ((!tagsNO && tagNOT) || tagCategoriesNOT?.length) {
    const tagsNOT = (typeof tagNOT === 'string' ? [tagNOT] : tagNOT) ?? [];
    const filterOutTags = tagCategoriesNOT?.length ? combineTags(args.categories, tagCategoriesNOT, tagsNOT) : tagsNOT;
    tickets = tickets.filter((ticket) => !filterOutTags.some((tag) => ticket.tags?.includes(tag)));
  }

  if (originalContact) {
    const search = String(originalContact).toLowerCase();

    tickets = tickets.filter((ticket) => {
      return ticket.originalContact?.toLowerCase().includes(search);
    });
  }

  if (originalDirection) {
    tickets = tickets.filter((ticket) => ticket.originalDirection === originalDirection);
  }

  if (lastContactAddress) {
    const search = String(lastContactAddress).toLowerCase();

    tickets = tickets.filter((ticket) => {
      return ticket.lastContactAddress?.toLowerCase().includes(search);
    });
  }

  return tickets;
};

export const getChannelName = (id: number, channels: Channel[]) =>
  channels.filter((channel: Channel) => channel.id === id)[0];

export const getPriorityName = (priorityGrade: number, priorities: Priority[]) =>
  priorities.filter((priority: Priority) => priority.value === priorityGrade)[0];

export const taskIdToNumericalId = (id: string | number): number =>
  typeof id === 'string' ? parseInt(id.substring(3), 10) : id;

export const coloredTagsFirst = sortBy(
  compose(
    (num) => !num,
    (arr) => (arr as Tag[]).length,
    filter(Boolean),
    props(['bgColor', 'fontColor']),
    prop('styles')
  )
);
