import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import TemplatesApi from '../api/TemplatesApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_TEMPLATES_FAILURE } from './index';
import { loadTemplatesSuccess } from 'src/reducers/templatesReducer';

export const loadTemplatesFailure = (templates: any[]) => {
  return { type: FETCH_TEMPLATES_FAILURE, payload: { templates } };
};

export const fetchTemplates: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TEMPLATES' }));
    return TemplatesApi.getTemplates()
      .then((templates) => {
        dispatch(
          loadTemplatesSuccess(
            templates.map((template) =>
              typeof template.template === 'string'
                ? { ...template, template: JSON.parse(template.template) }
                : template
            )
          )
        );
      })
      .catch((error) => {
        console.error('Failed to fetch all templates', error);
        // commented out because it's not handled, so triggers redundant reducers execution
        // dispatch(loadTemplatesFailure(error));
      });
  };
};
