import { intersection } from 'lodash-es';

import ApiConfig from '../api/ApiConfig';

export type TagTypes = 'context' | 'hidden';

export interface TagStyle {
  bgColor?: string | 'undefined';
  fontColor?: string | 'undefined';
}

export interface Tag {
  id: string;
  name: string;
  env?: string;
  type?: TagTypes;
  categories: string[];
  styles: TagStyle | null;
  metadata: any;
  created: number;
  numberOfContents?: number;
  numberOfComments?: number;
  deprecated: 0 | 1;
  ticketTypes: number[];
  weight: number;
}

export function tagIdEquals(id: string | number, tag: Tag): boolean {
  if (typeof id === 'number' || !id.startsWith('TAG')) {
    id = `TAG${id}`;
  }

  return tag.id === id;
}

export function tagIdToString(id: string | number): string {
  if (typeof id === 'number' || !id.startsWith('TAG')) {
    return `TAG${id}`;
  }

  return id;
}

export function tagIdToNumber(id: string | number): number {
  if (typeof id === 'number' || !id.startsWith('TAG')) {
    return Number(id);
  }

  return Number(id.substring(3));
}

export const isDefaultTag = (tagId: string): boolean => {
  return ApiConfig.getConfig().API_MAIN_CONTENT_TAGS.some((cTag) => cTag === tagId);
};

export const filterNonDefaultTags = (tags: Tag[]): Tag[] => {
  return tags.filter((tag) => !isDefaultTag(tag.id));
};

export const filterNonDefaultTagIds = (tags: string[]): string[] => {
  return tags.filter((tag) => !isDefaultTag(tag));
};

export const filterTagsByCategoryIds = (tags: Tag[], categoryIds: string[] | number[]): Tag[] => {
  const parsedCategoryIds = categoryIds.map((id) => (typeof id === 'number' ? `CAT${id}` : id));
  return tags.filter((tag) => intersection(tag.categories, parsedCategoryIds).length);
};

export const filterTagsByTagIds = (tags: Tag[], tagIds: string[]) => {
  return tags.filter((tag) => tagIds.includes(tag.id));
};
