import iziToast from 'izitoast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

import type { FC } from 'react';
import type { DropdownProps } from 'semantic-ui-react';

import { getManualSurveys, getSurveyAnswers, getSurveyLink } from './Api/SurveyRequest';
import Error from './Error';
import Notification from './Notification';
import Spinner from './Spinner';
import SurveyList from './SurveyList';

import type { Survey, SurveyAnswer } from 'src/types/Survey';

interface SurveyContainerProps {
  taskId: string;
}

export const SurveyContainer: FC<SurveyContainerProps> = ({ taskId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<'loading' | 'success' | 'error' | 'notification'>('loading');
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswer[]>([]);
  const [manualSurveys, setManualSurveys] = useState<Partial<Survey>[]>([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    getSurveys();
    getAnswers();
  }, [taskId]);

  const getSurveys = async () => {
    const data = await getManualSurveys(taskId);
    setManualSurveys(data);
  };

  const getAnswers = async () => {
    const taskNumber = taskId.match(/\d+/g)?.toString();
    if (!taskNumber) {
      setStatus('notification');
      setNotification(t('CASE_SURVEY_NOT_FOUND'));
      return;
    }

    setSurveyAnswers([]);
    setStatus('loading');

    try {
      const data = await getSurveyAnswers(taskNumber);
      if (Array.isArray(data) && data[0] !== undefined) {
        setSurveyAnswers(data);
        setStatus('success');
      } else {
        setStatus('notification');
        setNotification(t('CASE_SURVEY_NOT_FOUND'));
      }
    } catch (error: any) {
      console.error('Error while getting survey', error?.data);
      if (error?.status === 404) {
        setStatus('notification');
        setNotification('notification');
      } else {
        setStatus('error');
        setNotification(error?.message);
      }
    }
  };

  const getAndCopyLink = useCallback(
    async (surveyId: number) => {
      const link = await getSurveyLink(taskId, surveyId);
      await navigator.clipboard.writeText(link);
      iziToast.info({ message: t('COPIED') });
    },
    [taskId]
  );

  const onClickCopyDropdown = useCallback(
    async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      await getAndCopyLink(data.value as number);
      await getAnswers();
    },
    [taskId]
  );

  const manualSurveysOptions = useMemo(
    () =>
      manualSurveys.map((survey) => ({
        value: survey.id,
        text: survey.internalName
      })),
    [manualSurveys]
  );

  return (
    <div>
      {manualSurveysOptions.length && (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <Dropdown
            button
            className="button icon"
            floating
            icon="copy"
            labeled
            onChange={onClickCopyDropdown}
            options={manualSurveysOptions}
            pointing="top left"
            search
            selectOnBlur={false}
            text={t('CASE_SURVEY_COPY_LINK')}
          />
        </div>
      )}

      {status === 'error' && <Error taskId={taskId} className="error" text={notification} />}
      {status === 'loading' && <Spinner taskId={taskId} className="loading" />}
      {status === 'success' && <SurveyList taskId={taskId} surveys={surveyAnswers} getAndCopyLink={getAndCopyLink} />}
      {status === 'notification' && (
        <Notification type={['negative']} taskId={taskId} className="error" text={notification} />
      )}
    </div>
  );
};

export default React.memo(SurveyContainer);
