import React from 'react';
import { Translation } from 'react-i18next';
import { Menu, Message, Ref, Sticky, Tab } from 'semantic-ui-react';

import type { History } from 'history';
import type { match as ReactRouterMatchParams } from 'react-router';

import LoaderComponent from '../Loader/Loader';
import FeatureFlags from 'src/api/FeatureFlags';
import ErrorBoundary from 'src/ErrorBoundary';
import { Roles } from 'src/types/User';

import type { SettingsOverviewReduxProps } from 'src/containers/SettingsContainer';

import './SettingsOverview.css';

const AddUserForm = React.lazy(() => import('./AddUserForm'));
const ChangePasswordForm = React.lazy(() => import('./ChangePasswordForm'));
const UsersManagementContainer = React.lazy(() => import('../../containers/UsersManagementContainer'));
const ResponseTemplatesManagementContainer = React.lazy(
  () => import('../../containers/ResponseTemplatesManagementContainer')
);
const TemplatesManagementContainer = React.lazy(() => import('../../containers/TemplatesManagementContainer'));
const TicketTypesManagementContainer = React.lazy(() => import('../../containers/TicketTypesManagementContainer'));
const CampaignManagement = React.lazy(() => import('../../Components/Management/CampaignManagment/CampaignManagement'));
const EmailConfigurationManagementContainer = React.lazy(
  () => import('../../Components/Management/MailConfigurationManagement')
);
const CacheManagement = React.lazy(() => import('../Management/CacheManagement/CacheManagement'));
const SuppressionListManagment = React.lazy(() => import('../Management/SuppressionList/SuppressionListManagement'));
const TagManagementContainer = React.lazy(() => import('../../Components/Management/TagManagement/TagManagement'));
const CategoryManagment = React.lazy(() => import('../Management/Categories/CategoryManagment'));
const AutoSuggestionManagement = React.lazy(() => import('../Management/AutoSuggestionManagement'));
const FilterPresetsManagement = React.lazy(() => import('../Management/FilterPresets/FilterPresetsManagement'));
const ChannelManagementContainer = React.lazy(() => import('../../containers/ChannelManagementContainer'));
const PriorityManagementContainer = React.lazy(() => import('../../containers/PriorityManagementContainer'));
const SurveyManagementContainer = React.lazy(() => import('../../containers/SurveyManagementContainer'));
const SurveyTemplateManagementContainer = React.lazy(
  () => import('../Management/SurveyTemplate/SurveyTemplateManagement')
);
const PostIntegrationManagement = React.lazy(() => import('../Management/Rules/PostIntegrationRuleManagement'));
const NotificationManagementContainer = React.lazy(() => import('../../containers/NotificationManagementContainer'));
const ChatManagementContainer = React.lazy(() => import('../Management/Chat/ChatManagement'));
const EIdentificationManagment = React.lazy(
  () => import('../Management/EIdentificationManagment/EIdentificationManagement')
);
const PortalManagment = React.lazy(() => import('../Management/Portal/PortalManagment'));
const WorkingHoursContainer = React.lazy(() => import('../../Components/Management/WorkingHours'));
const Invoicing = React.lazy(() => import('./Invoicing'));
const KnowledgeBaseSettings = React.lazy(() => import('./KnowledgeBaseSettings/KnowledgeBaseSettings'));
const AIFunctionsManagement = React.lazy(
  () => import('../../Components/Management/AIFunctionsManagement/AIFunctionsManagement')
);
const WhatsappConnect = React.lazy(() => import('../../Components/Management/Whatsapp/WhatsappConnect'));

export interface SettingsOverviewProps extends SettingsOverviewReduxProps {
  trigger: React.ReactNode;
  activeTabKey: string;
  match: ReactRouterMatchParams;
  history: History;
  noMenuContainer?: boolean;
}

interface SettingsOverviewState {
  verticalTabs: boolean;
}

class SettingsOverview extends React.Component<SettingsOverviewProps, SettingsOverviewState> {
  contextRef = React.createRef<HTMLElement>();

  constructor(props: SettingsOverviewProps) {
    super(props);
    this.state = { verticalTabs: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const newVerticalTabs = window.innerWidth > 1024;
    if (newVerticalTabs !== this.state.verticalTabs) {
      this.setState({ verticalTabs: newVerticalTabs });
    }
  }

  private handleTabChange = (key: string) => {
    this.props.history.push('/settings/' + key);
    this.props.setActiveSettingsTab(key);
  };

  private publicPanes = [
    {
      menuItem: {
        key: 'changePassword',
        icon: 'key',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_CHANGEPASSWORD')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <ChangePasswordForm changePassword={this.props.changePassword} username={this.props.userData.profile.email} />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'userNotifications',
        icon: 'bell',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_NOTIFICATIONS')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('ENABLE_SOUND_NOTIFICATION'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <NotificationManagementContainer />
        </React.Suspense>
      )
    }
  ];

  private adminPanes = [
    {
      menuItem: {
        key: 'addUser',
        icon: 'add user',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_ADD_USER')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <AddUserForm />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'users',
        icon: 'users',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_USERS')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <div className="settings-main">
            <UsersManagementContainer />
          </div>
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'tags',
        icon: 'tag',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_TAGS')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <TagManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'categories',
        icon: 'tags',
        className: 'settings-button strictHidden',
        content: <Translation ns="translations">{(t) => t('GENERAL_CATEGORIES')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <CategoryManagment />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'rpaRules',
        icon: 'tasks',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_POST_INTEGRATION')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <PostIntegrationManagement />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'channels',
        icon: 'inbox',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <ChannelManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'responseTemplates',
        icon: 'book',
        className: 'settings-button',
        content: (
          <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_RESPONSE_TEMPLATES')}</Translation>
        )
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <ResponseTemplatesManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'templates',
        icon: 'book',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('management.templates_management.title')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <TemplatesManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        as: Menu.Item,
        key: 'ticketTypes',
        icon: 'folder outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_TICKET_TYPES')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('ENABLE_TICKET_TYPE_MANAGEMENT'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <TicketTypesManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        as: Menu.Item,
        key: 'eIdentificaiton',
        icon: 'handshake outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('E-Identification')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('ENABLE_EIDENTIFICATION'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <EIdentificationManagment />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'mailConfigurations',
        icon: 'envelope outline',
        className: 'settings-button',
        content: (
          <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_EMAIL_CONFIGURATION')}</Translation>
        )
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <EmailConfigurationManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'suppressionList',
        icon: 'ban',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_SUPPRESSION_LIST')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <SuppressionListManagment />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'autoSuggestions',
        icon: 'paste',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_AUTO_SUGGESTIONS')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <AutoSuggestionManagement />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'imports',
        icon: 'download',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_CAMPAIGN')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <CampaignManagement />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'priorities',
        icon: 'angle double up',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_TITLE_ADMIN_MANAGE_PRIORITIES')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <PriorityManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'surveys',
        icon: 'talk',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_SURVEY_TITLE')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('ENABLE_SURVEY_SETTINGS'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <SurveyManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'chats',
        icon: 'chat',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_CHATS_TITLE')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <ChatManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'surveyTemplates',
        icon: 'paper plane outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_SURVEY_TEMPLATES_TITLE')}</Translation>
      },
      // visible: FeatureFlags.isFlagOn("ENABLE_SURVEY_SETTINGS"),
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <SurveyTemplateManagementContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'workingHours',
        icon: 'clock outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('workingHours.settingsButtonName')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <WorkingHoursContainer />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'knowledgeBase',
        icon: 'file alternate outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('settings.knowledgebase.title')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          {FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && <KnowledgeBaseSettings />}
          {!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
            <div>
              <Message
                info
                icon="chat"
                header={
                  <Translation ns="translations">{(t) => t('settings.knowledgebase.feature_disabled')}</Translation>
                }
              />
            </div>
          )}
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'portal',
        icon: 'address card outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('SETTINGS_PORTAL_TITLE')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('CUSTOMER_PORTAL_ENABLED'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <PortalManagment />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'invoicing',
        icon: 'chart bar outline',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('settings.invoicing.title')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          {this.props.userData.permissions.includes('exportData') && <Invoicing />}
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'ai-templates',
        icon: 'microchip',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('settings.ai_templates.title')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('ENABLE_OPENAI'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <AIFunctionsManagement />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'whatsapp',
        icon: 'conversation',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('Whatsapp')}</Translation>
      },
      visible: FeatureFlags.isFlagOn('WHATSAPP_ENABLED'),
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <WhatsappConnect />
        </React.Suspense>
      )
    },
    {
      menuItem: {
        key: 'filterPresets',
        icon: 'filter',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('management.filter_presets.title')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <FilterPresetsManagement />
        </React.Suspense>
      )
    }
  ];

  private superAdminPanes = [
    {
      menuItem: {
        key: 'cache',
        icon: 'microchip',
        className: 'settings-button',
        content: <Translation ns="translations">{(t) => t('management.cache.title')}</Translation>
      },
      visible: true,
      render: () => (
        <React.Suspense fallback={<LoaderComponent />}>
          <CacheManagement />
        </React.Suspense>
      )
    }
  ];

  render() {
    const { verticalTabs } = this.state;
    const visiblePublicPanes = this.publicPanes.filter((pane) => pane.visible);
    const visibleAdminPanes = this.adminPanes.filter((pane) => pane.visible);
    const visibleSuperAdminPanes = this.superAdminPanes.filter((pane) => pane.visible);
    const panes = visiblePublicPanes;

    if (this.props.userRole && Roles.isAdminLike(this.props.userRole)) {
      panes.push(...visibleAdminPanes);
    }

    if (this.props.userRole && Roles.isSuperAdmin(this.props.userRole)) {
      panes.push(...visibleSuperAdminPanes);
    }

    /**
     * Container for the Tab Menu to make it use Sticky.
     *
     * For some reason the vertical-attribute needs to be set on this Menu Component
     * and the Tab Components menu-attribute. So if you remove it, check that the
     * vertical menu still works!
     *
     * @param props
     */
    const MenuContainer = (props: { children: React.ReactNode }) => (
      <Sticky context={this.contextRef}>
        <Menu fluid tabular attached vertical={verticalTabs} className="settingsSidebar__stickyMenu">
          {props.children}
        </Menu>
      </Sticky>
    );

    // react-router typings succ
    const pageName = (this.props.match.params as any)?.pageName;
    const activePaneByURLIndex = panes.findIndex((pane: { menuItem: { key?: string } }) => {
      return pane.menuItem?.key === pageName;
    });

    const menuProps: any = {
      fluid: true,
      tabular: true
    };

    if (!this.props.noMenuContainer) {
      menuProps.as = MenuContainer;
    }

    return (
      <ErrorBoundary>
        <Ref innerRef={this.contextRef}>
          <div className={'overview'}>
            <div className="settings">
              <ErrorBoundary>
                <div>
                  <Tab
                    activeIndex={activePaneByURLIndex}
                    menu={menuProps}
                    grid={{ paneWidth: 13, tabWidth: 3 }}
                    className={verticalTabs ? 'adminTabsPanelVertical' : 'adminTabsPanel'}
                    panes={panes}
                    onTabChange={(_, { activeIndex }) => {
                      const pane = panes[activeIndex as number];
                      this.handleTabChange(pane.menuItem.key);
                    }}
                  />
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </Ref>
      </ErrorBoundary>
    );
  }
}

export default SettingsOverview;
