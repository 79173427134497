import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import ChatsApi from '../api/ChatsApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_CHAT_STATUSES_SUCCESS } from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';

import type { Chat } from 'src/types/Chat';

export const loadChatsSuccess = createAction<Chat[]>(FETCH_CHAT_STATUSES_SUCCESS);

export const fetchChats = createAsyncThunk<{ status: 'success' | 'failed' }, undefined, { rejectValue: Error }>(
  'fetchChats',
  async (argument, { dispatch }) => {
    try {
      dispatch(startAjaxCall({ name: 'FETCH_CHATS' }));

      const chatConfigs = await ChatsApi.getChats();

      dispatch(loadChatsSuccess(chatConfigs));
      dispatch(initialRequestSuccess('chatStatuses'));
      return { status: 'success' };
    } catch (error) {
      console.error('ERROR fetchChats: ', error);
      dispatch(initialRequestFailure('chatStatuses', error));
      return { status: 'failed' };
    }
  }
);
