import { cloneDeep } from 'lodash-es';

import {
  UPDATE_INITIAL_REQUEST_STATUS_FAILURE,
  UPDATE_INITIAL_REQUEST_STATUS_LOADING,
  UPDATE_INITIAL_REQUEST_STATUS_SUCCESS
} from 'src/actions';

import type { InitialRequestState } from 'src/Components/InitialLoad';

const initRequests: Array<InitialRequestState> = [
  {
    type: 'users',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'roles',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'ticketTypes',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'responseTemplates',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'titleTemplates',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'channelTypes',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'linkLists',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'personalData',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'tags',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'categories',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'envSettings',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'featureFlags',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'PhoneConfigurations',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'chatStatuses',
    isLoading: true,
    isCompleted: false,
    error: null
  },
  {
    type: 'autoSuggestions',
    isLoading: true,
    isCompleted: false,
    error: null
  }
];

const initialState = {
  initialRequests: initRequests
};

const initialRequestReducer = (state = initialState.initialRequests, action: any) => {
  switch (action.type) {
    case UPDATE_INITIAL_REQUEST_STATUS_SUCCESS:
    case UPDATE_INITIAL_REQUEST_STATUS_FAILURE: {
      const otherRequestsStates = state.filter((reqState: InitialRequestState) => {
        return reqState.type !== action.payload.type;
      });
      const originalRequestState = state.find((reqState: InitialRequestState) => {
        return reqState.type === action.payload.type;
      });

      const newState = cloneDeep(originalRequestState);
      if (!newState) {
        return state;
      }
      if (action.payload.isCompleted) {
        newState.isCompleted = true;
      }
      newState.isLoading = false;
      newState.error = action.payload.error ? action.payload.error : null;
      return [...otherRequestsStates, newState];
    }
    case UPDATE_INITIAL_REQUEST_STATUS_LOADING: {
      const nonLoadingRequestsStates = state.filter((reqState: InitialRequestState) => {
        return reqState.type !== action.payload.type;
      });
      const loadingRequestState = state.find((reqState: InitialRequestState) => {
        return reqState.type === action.payload.type;
      });

      const loadingState = cloneDeep(loadingRequestState) || ({} as any);
      loadingState.isLoading = true;
      return [...nonLoadingRequestsStates, loadingState];
    }
    default: {
      return state;
    }
  }
};

export default initialRequestReducer;
