import ChannelType from './ChannelType';
import { EmailContent, SecureEmailContent } from './EmailContent';
import EmptyContent from './EmptyContent';
import InternalContent from './InternalContent';
import CustomerPortalContent from './PhoneContent';
import SmsContent from './SmsContent';
import { TampuuriContent } from './TampuuriContent';
import { WhatsappContent } from './WhatsappContent';

import type { Channel } from 'src/types/Channel';
import type { TicketType } from 'src/types/TicketType';
import EezyContent from './EezyContent';
import ViestiTytttiContent from './ViestiTyttiContent';
import InstagramContent from './InstagramContent';

interface CommentIconContentProps {
  channel?: ChannelType;
  ticketTypes: TicketType[];
  channels: Channel[];
  metaData: null | Record<string, any>;
  title: string | null;
  showSender?: boolean;
}

const contents = {
  [ChannelType.Email]: EmailContent,
  [ChannelType.PriorityMail]: EmailContent,
  [ChannelType.Internal]: InternalContent,
  [ChannelType.Sms]: SmsContent,
  [ChannelType.SecureEmail]: SecureEmailContent,
  [ChannelType.WhatsApp]: WhatsappContent,
  [ChannelType.Tampuuri]: TampuuriContent,
  [ChannelType.Phone]: CustomerPortalContent,
  [ChannelType.CustomerPortal]: CustomerPortalContent,
  [ChannelType.Eezy]: EezyContent,
  [ChannelType.ViestiTytti]: ViestiTytttiContent,
  [ChannelType.Instagram]: InstagramContent,
};

function CommentIconContent(props: CommentIconContentProps): JSX.Element | null {
  if (!props.channel) {
    return null;
  }
  const Content = contents[props.channel];

  return (Content == null || props.metaData == null ? EmptyContent : Content)(props);
}

export default CommentIconContent;
