import { createReducer } from '@reduxjs/toolkit';

import { loadChatsSuccess } from 'src/actions/chatActions';

import type { Chat } from 'src/types/Chat';

const initialState: Chat[] = [];

const chatConfigsReducer = createReducer(initialState, (builder) => {
  builder.addCase(loadChatsSuccess, (_, action) => {
    return action.payload;
  });
});

export default chatConfigsReducer;
