import { apiClient } from 'src/Utilities/httpClients';

export interface AnonymizationOptions {
  anonymizeTicketsData: boolean;
  anonymizeTicketCommentsData: boolean;
  deleteContentDetails: boolean;
  anonymizeSurveyAnswers: boolean;
  detachEntities: boolean;
  removeUnattachedEntities: boolean;
}

export default class AnonymizationApi {
  static anonymize = async (data: { ticketIds?: number[]; commentIds?: number[]; options?: AnonymizationOptions }) => {
    return apiClient.post('/anonymize', data).then((response) => response.data);
  };
}
