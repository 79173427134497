import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import AnonymizeTicket from './Anonymize/AnonymizeTicket';
import MergeTickets from './MergeTickets/MergeTickets';
import PrintComments from './PrintComments';
import FeatureFlags from 'src/api/FeatureFlags';
import { useModal } from 'src/Components/Modal/ModalContext';
import { Roles } from 'src/types/User';

import type { State } from 'src/types/initialState';

const AdvancedActions = () => {
  const { t } = useTranslation();
  const user = useSelector(
    (state: State) => state.usersList.usersList.find((user) => user.UID === state.userData.UID)!
  );
  const { openModal } = useModal();

  return (
    <Dropdown icon="ellipsis vertical" pointing="top right" button className="icon">
      <Dropdown.Menu>
        <PrintComments />

        {FeatureFlags.isFlagOn('MANUALLY_ANONYMIZE_COMMENT') && Roles.isAdmin(user.role.id) && (
          <Dropdown.Item onClick={() => openModal(<AnonymizeTicket />, 'tiny')}>
            {t('anonymization.labels.ticket_anonymization')}
          </Dropdown.Item>
        )}

        <Dropdown.Item onClick={() => openModal(<MergeTickets />)}>
          {t('top_bar.advanced_actions.merge_tickets.title')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdvancedActions;
