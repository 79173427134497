import { apiClient } from 'src/Utilities/httpClients';

import type { Chat } from 'src/types/Chat';

export default class ChatsApi {
  static getChats = async () => {
    return apiClient.get<Chat[]>('/chatStatuses').then((response) => response.data);
  };

  static patchChat = async (id: number, payload: Partial<Chat>) => {
    return apiClient.patch<number>(`/chatStatus/${id}`, payload).then((response) => response.data);
  };

  static toggleAllChats = async (status: 'enable' | 'disable') => {
    return apiClient.patch('/chatStatus', { status }).then((response) => response.data);
  };

  static createChat = async (payload: Partial<Chat>) => {
    return apiClient
      .post('/chatStatuses', payload)
      .then((response) => response.data)
      .catch((error) => {
        console.error('ERROR createChat: ', error);
        throw error;
      });
  };
}
