import { get } from 'lodash';
import { uniq } from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import type { ConnectedProps } from 'react-redux';

import CommentFiltersModal from './CommentFiltersModal';
import CommentsMenu from './CommentsMenu';
import Discussion from './Discussion';
import { getCommentSender } from 'src/Components/Utilities/comments';
import { setCommentsSorting } from 'src/reducers/commentsMenuReducer';
import { Direction } from 'src/types/Sorting';
import { CommentMetadataType } from 'src/types/Ticket';
import { filterComments } from 'src/Utilities/comments';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';
import type { SenderEmail } from 'src/types/TicketType';
import { useTranslation } from 'react-i18next';

const { ASC, DESC } = Direction;

interface CommentsProps extends ConnectedProps<typeof connector> {
  ticket: Ticket;
  senderEmails: SenderEmail[];
  mobileMode: boolean;
}

export const Comments = ({
  sorting,
  mobileMode,
  ticket,
  ticketTypes,
  usersList,
  senderEmails,
  commentFilters,
  setCommentsSorting,
  showEvents,
  channels
}: CommentsProps) => {
  const {t} = useTranslation();

  const [isCommentFiltersModalOpen, setCommentFiltersModalOpen] = useState(false);

  const comments = showEvents
    ? ticket.comments
    : ticket.comments.filter(
        (comment) =>
          comment.type !== 'automatic' ||
          (comment.metaData?.type && [CommentMetadataType.unansweredPhoneCall].includes(comment.metaData.type))
      );
  const filteredComments = filterComments(
    comments,
    commentFilters,
    ticket.entities,
    ticketTypes
      .find((ticketType) => ticketType.name === ticket.taskType)
      ?.fieldSets?.find((fieldSet) => fieldSet.id === 'customerInfo')?.customerInfo ?? []
  );

  const onDirectionClick = () => {
    setCommentsSorting(sorting === DESC ? ASC : DESC);
  };

  const getCommentAuthors = () =>
    uniq(
      ticket.comments.map((comment) => {
        const sender = getCommentSender({
          originalEntityData: get(comment, ['metaData', 'originalEntity'], null),
          channelData: channels.find((channel) => channel.id === comment.channel),
          user: usersList.find((user) => user.UID === comment.createdByUser)!,
          metaData: comment.metaData,
          direction: comment.direction,
          ticketEntities: ticket.entities,
          customerInfoFields:
            ticketTypes
              .find((ticketType) => ticketType.name === ticket.taskType)
              ?.fieldSets?.find((fieldSet) => fieldSet.id === 'customerInfo')?.customerInfo ?? []
        });

        return ['CHAT_ENTITY', 'FORM_ENTITY', 'UNKNOWN'].includes(sender) ? t(`COMMENT_AUTHOR_${sender}`) : sender;
      })
    );

  return (
    <div style={{ marginTop: '20px' }}>
      <CommentsMenu
        caseId={ticket.id}
        direction={sorting}
        mobileMode={mobileMode}
        onDirectionClick={onDirectionClick}
        showedCommentsLength={filteredComments.length}
        setCommentFiltersModalOpen={(state) => setCommentFiltersModalOpen(state)}
      />

      <Discussion
        task={ticket}
        ticketTypes={ticketTypes}
        senderEmails={senderEmails}
        users={usersList}
        channels={channels || []}
        comments={filteredComments}
        taskId={ticket.id}
        direction={sorting}
      />

      {isCommentFiltersModalOpen ? (
        <CommentFiltersModal
          caseId={ticket.id}
          commentAuthors={getCommentAuthors()}
          showedCommentsLength={filteredComments.length}
          totalCommentsAmount={comments.length}
          setCommentFiltersModalOpen={setCommentFiltersModalOpen}
        />
      ) : null}
    </div>
  );
};

const connector = connect(
  (state: State) => ({
    sorting: state.commentsMenu.sorting,
    ticketTypes: state.ticketTypes,
    usersList: state.usersList.usersList,
    channels: state.channels,
    commentFilters: state.commentFilters[state.activeTicketTab!],
    showEvents: state.commentsMenu.showEvents
  }),
  { setCommentsSorting }
);

export default connector(Comments);
